<template>
  <div>
    <a-card title="采购询价创建">
      <div slot="extra" style="margin: -4px 0">
        <a-button ghost icon="left" type="primary" @click="$router.go(-1)">返回</a-button>
      </div>

      <a-spin :spinning="createLoading">
        <a-form-model ref="form" :label-col="{ span: 8 }" :model="formData" :rules="rules" :wrapper-col="{ span: 16 }">
          <a-row>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item label="采购询价单号" prop="number">
                <a-input v-model="formData.number" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item label="供应商" prop="supplier_set">
                <SupplierMultSelect v-model="formData.supplier_set" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item label="截止时间" prop="closing_time">
                <a-date-picker
                  v-model="formData.closing_time"
                  placeholder="请选择时间"
                  valueFormat="YYYY-MM-DD HH:mm:ss"
                  show-time
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item label="备注" prop="remark">
                <a-input v-model="formData.remark" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-item prop="sales_quotation_files" label="附件">
                <PurchaseInquiryFileUpload v-model="formData.purchase_inquiry_files" :initialItems="[]" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form-model>

        <a-divider orientation="left">物料信息</a-divider>

        <div>
          <div>
            <a-button type="primary" @click="selectModalVisible = true">添加物料</a-button>
          </div>
          <div style="margin-top: 16px">
            <a-table :columns="columns" :dataSource="goodsData" :pagination="false" rowKey="id" size="small">
              <div slot="purchase_quantity" slot-scope="value, item, index">
                <a-input-number v-model="item.purchase_quantity" size="small" style="width: 100px" />
              </div>
              <div slot="action" slot-scope="value, item, index">
                <a-button-group size="small">
                  <a-button type="danger" @click="removeGoods(item)">移除</a-button>
                </a-button-group>
              </div>
            </a-table>
          </div>
        </div>
      </a-spin>

      <div style="margin-top: 32px">
        <a-popconfirm title="确定创建吗?" @confirm="create">
          <a-button :loading="createLoading" type="primary">创建</a-button>
        </a-popconfirm>
      </div>
    </a-card>

    <PurchaseInventorySelectModal v-model="selectModalVisible" @select="onSelectGoods" />
  </div>
</template>

<script>
import { purchaseInquiryOrderNumber, purchaseInquiryOrderCreate } from "@/api/purchasing";
import NP from "number-precision";
import moment from "moment";

export default {
  components: {
    SupplierMultSelect: () => import("@/components/SupplierMultSelect"),
    PurchaseInventorySelectModal: () => import("@/components/PurchaseInventorySelectModal"),
    PurchaseInquiryFileUpload: () => import("@/components/PurchaseInquiryFileUpload"),
  },
  data() {
    return {
      createLoading: false,
      formData: {},
      goodsData: [],

      selectModalVisible: false,
      rules: {
        number: [
          { required: true, message: "请输入采购申请单号", trigger: "change" },
          { max: 32, message: "超出最大长度 (32)", trigger: "change" },
        ],
        supplier_set: [{ required: true, message: "请选择供应商", trigger: "change" }],
        closing_time: [{ required: true, message: "请选择截止时间", trigger: "change" }],
        remark: [{ max: 256, message: "超出最大长度 (256)", trigger: "change" }],
      },
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          width: 60,
          fixed: "left",
          customRender: (value, item, index) => index + 1,
        },
        {
          title: "物料编号",
          dataIndex: "goods_number",
        },
        {
          title: "物料名称",
          dataIndex: "goods_name",
        },
        {
          title: "物料条码",
          dataIndex: "goods_barcode",
        },
        {
          title: "物料规格",
          dataIndex: "goods_spec",
        },
        {
          title: "物料单位",
          dataIndex: "goods_unit",
        },
        {
          title: "采购数量",
          dataIndex: "purchase_quantity",
          scopedSlots: { customRender: "purchase_quantity" },
        },
        {
          title: "操作",
          dataIndex: "action",
          width: 80,
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  methods: {
    initData() {
      this.formData = {
        number: "",
        supplier_set: [],
        closing_time: moment().add(1, "day").startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        remark: "",
      };
      purchaseInquiryOrderNumber().then((data) => {
        this.formData.number = data.number;
      });
    },
    onSelectGoods(item) {
      this.goodsData.push({
        goods: item.id,
        goods_number: item.number,
        goods_name: item.name,
        goods_barcode: item.barcode,
        goods_spec: item.spec,
        goods_unit: item.unit_name,
        purchase_quantity: 1,
      });
    },
    removeGoods(item) {
      this.goodsData = this.$functions.removeItem(this.goodsData, item);
    },
    create() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.goodsData.length === 0) {
            this.$message.warning("请选择物料");
            return;
          }

          const formData = {
            ...this.formData,
            purchase_inquiry_goods_items: this.goodsData.map((item) => ({
              goods: item.goods,
              purchase_quantity: item.purchase_quantity,
            })),
          };

          this.createLoading = true;
          purchaseInquiryOrderCreate(formData)
            .then((data) => {
              this.$message.success("创建成功");
              this.$router.go(-1);
            })
            .finally(() => {
              this.createLoading = false;
            });
        }
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
